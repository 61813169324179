* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'lcddot';
  src: url('../public/fonts/lcddot.eot');
  src: url('../public/fonts/lcddot.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/lcddot.woff') format('woff'),
    url('../public/fonts/lcddot.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  padding: 0;
  font-family: 'lcddot', Courier, monospace;
  background-color: #030c1c;
  color: #fff;
  overflow: hidden;
  perspective: 650px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
