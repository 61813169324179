@font-face {
  font-family: 'neontubes';
  src: url('../../../public/fonts/neontubes-webfont.eot');
  src: url('../../../public/fonts/neontubes-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/neontubes-webfont.woff2') format('woff2'),
    url('../../../public/fonts/neontubes-webfont.woff') format('woff'),
    url('../../../public/fonts/neontubes-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@keyframes buzz {
  70% {
    opacity: 0.8;
  }
}

@keyframes blink {
  40% {
    opacity: 1;
  }
  42% {
    opacity: 0.8;
  }
  43% {
    opacity: 1;
  }
  45% {
    opacity: 0.2;
  }
  46% {
    opacity: 1;
  }
}

.neon-text {
  font-family: 'neontubes';
}

.blink {
  animation: blink 4s infinite alternate;
}
.buzz {
  animation: buzz 0.01s infinite alternate;
}
